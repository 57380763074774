import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {FooterService} from 'src/app/ui/service/footer.service';
import {NavbarService} from 'src/app/ui/service/navbar.service';

@Component({
  selector: 'app-subscription-success',
  templateUrl: './subscription-success.component.html',
  styleUrls: ['./subscription-success.component.scss'],
})
export class SubscriptionSuccessComponent implements OnInit, OnDestroy {
  subRoutSub$!: Subscription;
  application_no!: string;
  serviceType!: string;
  isCopied: boolean = false;
  constructor(
    private _activeRoute: ActivatedRoute,
    private _navbar: NavbarService,
    private _footer: FooterService,
    private _router: Router,
  ) {
    //set navbar to visible
    this._navbar.setNavbarVisiabilty(true);
    this._footer.setFooterVisibility(true);
    this.subRoutSub$ = this._activeRoute.params.subscribe((params) => {
      this.serviceType = params['serviceType'];
      this.application_no = params['applicationNum'];
    });
  }

  ngOnInit(): void {}

  goToDetailsPage(){
    switch (this.serviceType){
      case 'case': this._router.navigateByUrl('/cases/my-cases');
      break;
      case 'inquiry': this._router.navigateByUrl('inquiry/my-inquiries');
      break;
    }
  }

  copyToClipboard(event: any): void {
    navigator.clipboard.writeText(this.application_no);
    event.target.classList.add('active');
    this.isCopied = true;
  }

  ngOnDestroy(): void {
    this.subRoutSub$?.unsubscribe();
  }
}
