import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-subscription-flow-stepper',
  templateUrl: './subscription-flow-stepper.component.html',
  styleUrls: ['./subscription-flow-stepper.component.scss'],
})
export class SubscriptionFlowStepperComponent implements OnInit, OnDestroy {
  @Input('currentStep') currentStep: number = 1;
  @Input('serviceType') serviceType!: string;
  @Input('disablePreviousStep') disablePreviousStep: boolean = false;

  subRoutSub$!: Subscription;
  hasPaymentSubscription!: boolean;
  serviceId!: number;
  caseType_id!: number;
  constructor(
    private _elemRef: ElementRef,
    private _activeRoute: ActivatedRoute,
    private _router: Router,
  ) {
    this.caseType_id = Number(this._activeRoute.snapshot.paramMap.get('id'));
    this.subRoutSub$ = this._activeRoute.queryParamMap.subscribe(
      (data: any) => {
        if (data.params.serviceId) {
          this.serviceId = +data.params.serviceId;
        }
      },
    );
  }

  ngOnInit(): void {}

  //for choosing step by click on icon or section title
  activateStep(event: any) {
    const newIndex = Number(
      event.target.closest('.step').getAttribute('data-index'),
    );
    if (newIndex > this.currentStep) return;
    this.currentStep = newIndex;
    const steps = this._elemRef.nativeElement.querySelectorAll('.step');
    steps.forEach((elem: any) => {
      elem.classList.remove('active');
    });
    event.target.closest('.step').classList.add('active');

    const queryParam = {queryParams: {serviceId: this.serviceId}};
    switch (this.currentStep) {
      case 1:
        this._router.navigate(['/services'], queryParam);
        break;
      case 2:
        this._router.navigate(['/casetype'], queryParam);
        break;
      case 3:
        if (this.serviceType === 'inquiry') {
          this._router.navigate(['/inquiry/create'], queryParam);
        } else {
          this._router.navigate(['/casetype', this.caseType_id], queryParam);
        }
        break;
    }
  }

  ngOnDestroy(): void {
    this.subRoutSub$?.unsubscribe();
  }
}
