import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ServicesListService} from 'src/app/case-type/services/services-list.service';
import {MainService} from 'src/app/core/interfaces/main-service';
import {ScrollService} from '../../service/scroll.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-service-plans',
  templateUrl: './service-plans.component.html',
  styleUrls: ['./service-plans.component.scss'],
})
export class ServicePlansComponent implements OnInit {
  servicesListSub!: Subscription;
  mainServices: MainService[] | null = null;
  hasAutoDiscount: boolean = true;
  @ViewChild('el') el!: ElementRef;
  scrollSub$!: Subscription;
  subRoutSub$!: Subscription;
  serviceId!: string;
  constructor(
    private _ServicesListService: ServicesListService,
    private _ScrollService: ScrollService,
    private _activeRoute: ActivatedRoute,
  ) {
    //get all main services
    this.servicesListSub = this._ServicesListService
      .getMainServices()
      .subscribe({
        next: (data) => {
          this.mainServices = data;
        },
        complete: () => {
          this.subRoutSub$ = this._activeRoute.queryParamMap.subscribe(
            (urlData: any) => {
              this.serviceId = urlData.params.serviceId;
              if (this.serviceId) {
                setTimeout(() => {
                  // this.removeQueryParam(urlData.params.servicePlansId);
                  // this.scrollIntoView(`_${urlData.params.servicePlansId}`);
                  this.scrollIntoView(`_${this.serviceId}`);
                }, 500);
              }
            },
          );
        },
      });
  }

  ngOnInit(): void {
    this.scrollSub$ = this._ScrollService
      .getScrollEvent()
      .subscribe((elementId) => {
        this.scrollIntoView(elementId);
      });
  }

  scrollIntoView(elementId: string) {
    const targetElement = document.body.querySelector(`#${elementId}`);
    if (targetElement) {
      const targetElementRect = targetElement.getBoundingClientRect();
      window.scroll({
        behavior: 'smooth',
        top: targetElementRect.top + window.scrollY - 60,
      });
    } else {
      const targetElementRect = this.el.nativeElement.getBoundingClientRect();
      window.scroll({
        behavior: 'smooth',
        top: targetElementRect.top + window.scrollY - 60,
      });
    }
  }

  removeQueryParam(paramName: string): void {
    const url = new URL(window.location.href);
    url.searchParams.delete(paramName);

    // Update the URL without triggering a page reload
    history.replaceState({}, document.title, url.toString());
  }

  ngOnDestroy(): void {
    if (this.servicesListSub) this.servicesListSub.unsubscribe();
    if (this.scrollSub$) this.scrollSub$.unsubscribe();
  }
}
