<div class="carousel-container d-flex" *ngIf="ads && ads.length > 0">
    <div class="carousel">
        <div class="carousel-inner" [style.transform]="getTransform()" [style.transition]="transitionStyle">
            <div class="carousel-item d-flex" *ngFor="let item of ads; let i = index"
                [class.active]="i === activeIndex">
                <img [src]=" !isMobile? item.desktopImageUrl : item.mobileimageUrl" alt="ad">
            </div>
        </div>

        <div class="carousel-actions d-flex">
            <button class="carousel-control prev" (click)="prev()">
                <span class="material-icons-outlined"> arrow_forward_ios </span>
            </button>

            <div class="carousel-indicators">
                <span *ngFor="let item of ads; let i = index" (click)="goTo(i)"
                    [class.active]="i === activeIndex"></span>
            </div>

            <button class="carousel-control next" (click)="next()">
                <span class="material-icons-outlined"> arrow_back_ios </span>
            </button>
        </div>

    </div>
</div>