import {Component, OnDestroy, OnInit} from '@angular/core';
import {Ad} from 'src/app/core/interfaces/ad';
import {AdsService} from '../../services/ads.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-ad-slider',
  templateUrl: './ad-slider.component.html',
  styleUrls: ['./ad-slider.component.scss'],
})
export class AdSliderComponent implements OnInit, OnDestroy {
  ads!: Ad[];
  autoSlide = true;
  interval = 8000;
  activeIndex = 0;
  transitionStyle = 'transform 0.5s ease-in-out';

  isMobile: boolean = false;
  adSub$!: Subscription;

  constructor(private _AdsService: AdsService) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;

    this.adSub$ = this._AdsService.getAds().subscribe({
      next: (data: any) => {
        this.ads = data;
      },
    });

    if (this.autoSlide) {
      setInterval(() => {
        this.next();
      }, this.interval);
    }
  }

  getTransform() {
    return `translateX(${this.activeIndex * 100}%)`;
  }

  next() {
    this.activeIndex = (this.activeIndex + 1) % this.ads.length;
  }

  prev() {
    this.activeIndex =
      (this.activeIndex - 1 + this.ads.length) % this.ads.length;
  }

  goTo(index: number) {
    this.activeIndex = index;
  }

  ngOnDestroy(): void {
    this.adSub$?.unsubscribe();
  }
}
