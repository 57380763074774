import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Ad} from 'src/app/core/interfaces/ad';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdsService {
  constructor(private _http: HttpClient) {}

  getAds(): Observable<Ad[]> {
    return this._http.get<Ad[]>(`${environment.api_url}/user/ads`);
  }
}
