<div class="stepper-container">
    <!-- step one  -->
    <div class="step" [attr.data-index]="1" [class.active]="currentStep == 1" [class.Disbaled]="disablePreviousStep">
      <div class="icon" (click)="activateStep($event)">
        <mat-icon (click)="activateStep($event)" aria-hidden="false">check_circle</mat-icon>
      </div>
      <p class="step-title">الخدمات و الأسعار</p>
    </div>
    <!-- step two  -->
    <div class="step" [attr.data-index]="2" [class.active]="currentStep == 2" *ngIf="serviceType !== 'inquiry'"  [class.Disbaled]="disablePreviousStep">
      <div class="icon" (click)="activateStep($event)">
        <mat-icon (click)="activateStep($event)" aria-hidden="false">{{currentStep >= 2? 'check_circle' : 'radio_button_checked'}}</mat-icon>
      </div>
      <p class="step-title">أختر الخدمة</p>
    </div>
    <!-- step three -->
    <div class="step" [attr.data-index]="3" [class.active]="currentStep == 3"  [class.Disbaled]="disablePreviousStep">
      <div class="icon" (click)="activateStep($event)">
        <mat-icon (click)="activateStep($event)" aria-hidden="false">{{currentStep >= 3? 'check_circle' : 'radio_button_checked'}}</mat-icon>
      </div>
      <p class="step-title">تعبئة البيانات</p>
    </div>
    <!-- step four -->
    <div class="step" [attr.data-index]="4" [class.active]="currentStep == 4">
      <div class="icon" (click)="activateStep($event)">
        <mat-icon (click)="activateStep($event)" aria-hidden="false">{{currentStep >= 4? 'check_circle' : 'radio_button_checked'}}</mat-icon>
      </div>
      <p class="step-title">تهانينا</p>
    </div>
  </div>