<div class="success-page-container">
    <app-subscription-flow-stepper [currentStep]="4" [serviceType]="serviceType" [disablePreviousStep]="true"></app-subscription-flow-stepper>
    <div class="success-message-wrapper">
        <img class="success-img" src="../../../../assets/images/checkMark-secondary.svg" alt="Success">
        <div class="success-title">
            تهانينا
        </div>
        <div class="success-body" *ngIf="serviceType == 'case'">
            تم تقديم طلب قضيتك بنجاح وارسال رقم القضية الخاص بك على رقم جوالك، رقم القضية الخاص بك هو
        </div>
        <div class="success-body" *ngIf="serviceType == 'inquiry'">
            تم تقديم طلب استشارتك بنجاح وارسال رقم الاستشارة الخاص بك على رقم جوالك، رقم الاستشارة الخاص بك هو
        </div>

        <div class="application-num">
            {{application_no}}
            <mat-icon matTooltipClass="tooltip-payment" [matTooltip]="isCopied ? 'تم النسخ' : ''"
                matTooltipPosition="above" aria-hidden="false" *ngIf="!isCopied" aria-label="Example contect_copy icon"
                (click)="copyToClipboard($event)" class="material-icons-outlined copy-icon">content_copy</mat-icon>
            <mat-icon aria-hidden="false" *ngIf="isCopied" aria-label="Example task icon"
                (click)="copyToClipboard($event)" class="material-icons-outlined copy-icon active">task_alt</mat-icon>
        </div>

        <div class="action-btn" (click)="goToDetailsPage()">
            {{ serviceType == 'case' ? 'متابعة القضية' : 'متابعة الإستشارة' }}
        </div>
    </div>
</div>