import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {map, Subscription} from 'rxjs';
import {CasesService} from 'src/app/cases/service/cases.service';
import {Section} from 'src/app/core/interfaces/case-type.interface';
import {AuthService} from 'src/app/core/services/auth.service';
import {ConfirmDialogComponent} from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {MessageDialogComponent} from 'src/app/shared/dialogs/message-dialog/message-dialog.component';
import {CheckLoginDialogComponent} from '../../../shared/dialogs/check-login-dialog/check-login-dialog.component';
import {TermsDialogComponent} from '../../dialogs/terms-dialog/terms-dialog.component';
import {CaseTypeService} from '../../services/case-type.service';
import {PaymentSubscriptionService} from 'src/app/shared/services/payment-subscription.service';

@Component({
  selector: 'app-casetype-stepper',
  templateUrl: './casetype-stepper.component.html',
  styleUrls: ['./casetype-stepper.component.scss'],
})
export class CasetypeStepperComponent implements OnInit {
  sections: Section[] | null = null;
  @Input() caseTypeId!: number;
  @Input() hasReconciliation!: boolean;
  @ViewChild('myform') myForm!: NgForm;
  currentIndex: number = 0;
  sectionsSub!: Subscription;
  routeSub!: Subscription;
  activeRouteSub!: Subscription;
  updateCaseSub!: Subscription;
  createCaseSub!: Subscription;
  answers: any = {}; //TODO create interface
  usercase!: any;
  draftId!: number;
  isUpdating: boolean = false;
  modifyObject: any = {};
  usercaseId!: number;
  public screenWidth: any = window.innerWidth;
  @ViewChild('questionElement') questionElement!: any;
  lawsuitData: boolean = false;
  lawsuitTerms: boolean = false;
  @ViewChild('lawsuitDataElement') lawsuitDataElement!: ElementRef;
  caseSub$!: Subscription;
  paymentSubscriptionSub$!: Subscription;
  subRoutSub$!: Subscription;
  hasPaymentSubscription!: boolean;
  serviceId!: number;
  @Input() draftServiceId!: number;
  isLoading: boolean = false;
  @Output() totalQuestionsCount = new EventEmitter<number>();
  constructor(
    private _route: ActivatedRoute,
    private _elemRef: ElementRef,
    private _caseTypeService: CaseTypeService,
    private _dialog: MatDialog,
    private _auth: AuthService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _casesService: CasesService,
    private _PaymentSubscriptionService: PaymentSubscriptionService,
    private _snackBar: MatSnackBar,
  ) {
    this.caseTypeId = this.caseTypeId
      ? this.caseTypeId
      : Number(this._activeRoute.snapshot.paramMap.get('id'));

    this.routeSub = this._route.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((data) => {
        if (data.usercaseId) {
          this.isUpdating = true;
          this.modifyObject = JSON.parse(data.modifiyQuestions);
          this.answers = data.usercaseAnswers;
          this.usercaseId = data.usercaseId;
        }
      });

    const isUserLogged = this._auth.user.currentUser;
    if (isUserLogged) {
      // this.caseSub$ = this._caseTypeService
      //   .checkUserHasCase(Number(this._activeRoute.snapshot.paramMap.get('id')))
      //   .subscribe((res) => {
      //     if (res != null) {
      //       this.modifyObject = JSON.parse(res.modfiy_json);
      //       this.answers = res.answers;
      //       this.usercaseId = res.id;
      //     }
      //   });

      this.routeSub = this._activeRoute.queryParamMap.subscribe((data: any) => {
        if (data.params.serviceId) {
          this.serviceId = +data.params.serviceId;
          this.paymentSubscriptionSub$ = this._PaymentSubscriptionService
            .checkPaymentSubscription(data.params.serviceId)
            .subscribe({
              next: (data) => {
                if (data) {
                  this.hasPaymentSubscription = true;
                } else {
                  this.hasPaymentSubscription = false;
                  this._snackBar.open('برجاء الاطلاع على الخدمات والأسعار أولاً', 'X', {
                    panelClass: 'error-snackbar',
                    duration: 1500,
                  });
                  this._router.navigate([`/services`], {
                    queryParams: {serviceId: this.serviceId},
                  });
                }
              },
            });
        }
      });
    }
  }

  ngOnInit(): void {
    this.sectionsSub = this._caseTypeService
      .getCaseDetails(this.caseTypeId)
      .subscribe((data) => {
        this.sections = data;
        // let totalNum = 0;
        // this.sections.forEach((section) => {
        //   totalNum += section.questions?.length ?? 0;
        // });
        if (this.sections) {
          this.totalQuestionsCount.emit(
            this.sections[this.sections.length - 1].questions?.length ?? 0,
          );
        }
      });

    const isThereSavedcase: any = sessionStorage.getItem('casetype');
    if (isThereSavedcase) {
      const savedIqnuiry = JSON.parse(isThereSavedcase);
      this.answers = savedIqnuiry;
      sessionStorage.removeItem('casetype');
      sessionStorage.removeItem('last-step');
    }
    this.activeRouteSub = this._activeRoute.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((data) => {
        if (data.draftAnswer) {
          this.answers = data.draftAnswer;
        }
      });
    this.scrollQuestion();
  }

  //for choosing step by click on icon or section title
  activateStep(event: any) {
    const newIndex = Number(
      event.target.closest('.step').getAttribute('data-index'),
    );
    if (newIndex > this.currentIndex) return;
    this.currentIndex = newIndex;
    const steps = this._elemRef.nativeElement.querySelectorAll('.step');
    steps.forEach((elem: any) => {
      elem.classList?.remove('active');
    });
    event.target.closest('.step').classList?.add('active');
    if (this.sections) {
      this.totalQuestionsCount.emit(
        this.sections[this.currentIndex].questions?.length ?? 0,
      );
    }
  }

  //for going to next step (if only current section all fields is valid)
  nextStep(sectionSlug: string) {
    if (this._elemRef.nativeElement.querySelectorAll('.step').length > 1) {
      const allStepsCount =
        this._elemRef.nativeElement.querySelectorAll('.step').length;
      let currentStep =
        this._elemRef.nativeElement.querySelector('.step.active');
      currentStep.classList?.remove('active');
      this.answers[sectionSlug] = this.myForm.form.value;
      if (allStepsCount <= this.currentIndex + 1) return;
      ++this.currentIndex;
      const nextSteps = this._elemRef.nativeElement.querySelector(
        `.step[data-index="${this.currentIndex}"]`,
      );
      if (this.screenWidth <= 768) {
        this._elemRef.nativeElement.querySelector(
          `.step[data-index="${this.currentIndex - 1}"]`,
        ).style.display = 'none';
        nextSteps.style.display = 'flex';
      }
      nextSteps.classList?.add('active');
      if (this.sections) {
        let extras = 0;
        if ((this.currentIndex = this.sections.length - 1)) {
          extras += this.hasReconciliation ? 3 : 2;
        }
        this.totalQuestionsCount.emit(
          (this.sections[this.currentIndex].questions?.length ?? 0) + extras,
        );
      }
    }
  }

  //for going to previous step
  prevStep() {
    if (this.currentIndex <= 0) return;
    const allStepsCount =
      this._elemRef.nativeElement.querySelectorAll('.step').length;
    let currentStep = this._elemRef.nativeElement.querySelector('.step.active');
    currentStep.classList?.remove('active');
    --this.currentIndex;
    const prevStep = this._elemRef.nativeElement.querySelector(
      `.step[data-index="${this.currentIndex}"]`,
    );
    if (this.screenWidth <= 768) {
      this._elemRef.nativeElement.querySelector(
        `.step[data-index="${this.currentIndex + 1}"]`,
      ).style.display = 'none';
      prevStep.style.display = 'flex';
    }

    prevStep.classList?.add('active');
    if (this.sections) {
      this.totalQuestionsCount.emit(
        this.sections[this.currentIndex].questions?.length ?? 0,
      );
    }
  }

  //for saving every section answers as nested object inside the answers object
  saveAnswers(sectionSlug: string) {
    this.answers[sectionSlug] = this.myForm.value;
    this.checkFormValid();
    this.scrollQuestion();
    if (
      this.myForm.form.status == 'VALID' &&
      Object.keys(this.modifyObject).length === 0
    ) {
      this.checkValueModify();
    }
    if (
      this.myForm.form.status === 'VALID' &&
      this.questionElement.nativeElement.querySelectorAll('.modify').length == 0
    ) {
      this.nextStep(sectionSlug);
    }
  }

  //for check whether user check reconciliation or not
  checkReconciliation(value: boolean) {
    this.answers.reconciliation = value;
  }

  //save answers values to answers state
  submitAnswers(slug: string) {
    this.answers[slug] = this.myForm.value;
  }

  //create user with answers state
  createCase(slug: string) {
    this.answers.lawsuitTerms
      ? (this.lawsuitTerms = this.answers.lawsuitTerms)
      : '';
    this.answers.lawsuitData
      ? (this.lawsuitData = this.answers.lawsuitData)
      : '';

    this.myForm.ngSubmit.emit();
    this.scrollQuestion();
    const isUserLogged = this._auth.user.currentUser;
    if (!isUserLogged) {
      const dialogRef = this._dialog.open(CheckLoginDialogComponent, {
        panelClass: 'white-dialog',
        width: '58rem',
        height: '39rem',
        data: {
          message: 'برجاء تسجيل الدخول لاستكمال طلبك',
        },
      });
      sessionStorage.setItem('casetype', JSON.stringify(this.answers));
      sessionStorage.setItem('last-step', this._router.url);
    } else {
      this.isLoading = true;
      this.checkFormValid();

      if (
        this.lawsuitData === true &&
        this.lawsuitTerms === true &&
        this.answers.reconciliation != undefined &&
        this.myForm.form.status == 'VALID'
      ) {
        delete this.answers.lawsuitTerms;
        delete this.answers.lawsuitData;
        if (this.isUpdating) {
          this.updateCaseSub = this._casesService
            .updateUsercasesById(this.usercaseId, {answers: this.answers})
            .subscribe({
              next: (data) => {
                this._snackBar.open('تم تعديل طلبك بنجاح', undefined, {
                  panelClass: 'success-snackbar',
                  duration: 1500,
                });
                this.isLoading = false;
                this._router.navigateByUrl('/cases/my-cases');
              },
            });
        } else {
          if (!this.hasPaymentSubscription) {
            this.isLoading = false;
            this._PaymentSubscriptionService.setEntityObject({
              answers: this.answers,
              case_id: this.caseTypeId,
            });
            if (this.serviceId) {
              this._router.navigate([`/services/${this.serviceId}`]);
            } else {
              this._router.navigate([`/services/${this.draftServiceId}`]);
            }
          } else {
            this.createCaseSub = this._caseTypeService
              .createCase({
                answers: this.answers,
                case_id: this.caseTypeId,
                serviceId: this.serviceId,
              })
              .subscribe({
                next: (data) => {
                  // const dialogRef = this._dialog.open(MessageDialogComponent, {
                  //   panelClass: 'secondry-dialog',
                  //   width: '87rem',
                  //   height: 'auto',
                  //   data: {
                  //     application_no: data.application_no,
                  //     message:
                  //       'تم تقديم طلبك بنجاح وارسال رقم الطلب الخاص بك على رقم جوالك : رقم الطلب الخاص بك هو',
                  //   },
                  // });
                  // dialogRef.afterClosed().subscribe(() => {
                  //   this._router.navigateByUrl('');
                  // });
                  this.isLoading = false;
                  this._router.navigate(['subscription-success','case', data.application_no]);
                },
                error: (err) => {
                  if (err.error.error.statusCode === 404) {
                    this._snackBar.open('لا يوجد اشتراك لهذه الخدمة', 'X', {
                      panelClass: 'error-snackbar',
                    });
                    this._router.navigate(['']);
                  }
                },
              });
          }
        }
      } else {
        if (Object.keys(this.modifyObject).length > 0) {
          this.saveAnswers(slug);
        }
        this.checkLawsuitPrivacyRequired();
        this.checkReconciliationRequired();
      }
    }
  }

  // check form if invalid to get modify class and scroll to question
  checkFormValid() {
    let formRequired = [];
    for (let x in this.myForm.form.controls) {
      if (this.myForm.form.controls[x].status === 'INVALID') {
        formRequired.push(x);
        this._caseTypeService.emitEvent([...new Set(formRequired)]);
      }
    }
    this.isLoading = false;
  }

  // check lawsuit terms and condition required
  checkLawsuitPrivacyRequired() {
    if (this.lawsuitData === false) {
      this.lawsuitDataElement.nativeElement.children[0].style.color = 'red';
    } else {
      this.lawsuitDataElement.nativeElement.children[0].style.color = 'grey';
    }

    if (this.lawsuitTerms === false) {
      this.lawsuitDataElement.nativeElement.children[1].style.color = 'red';
    } else {
      this.lawsuitDataElement.nativeElement.children[1].style.color = 'grey';
    }
  }

  // check lawsuit Reconciliation required
  checkReconciliationRequired() {
    if (this.answers.reconciliation == undefined) {
      this.questionElement.nativeElement.querySelectorAll(
        '.question-reconciliation',
      )[0].children[0].style.border = '1px solid red';
      this.questionElement.nativeElement.querySelectorAll(
        '.question-reconciliation',
      )[0].children[0].children[0].style.color = 'red';
    } else {
      this.questionElement.nativeElement.querySelectorAll(
        '.question-reconciliation',
      )[0].children[0].style.border = '';
      this.questionElement.nativeElement.querySelectorAll(
        '.question-reconciliation',
      )[0].children[0].children[0].style.color = '';
    }
  }

  //cancel submit and redirect to homepage
  cancelSubmit() {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      panelClass: 'white-dialog',
      width: '58rem',
      height: '39rem',
      autoFocus: false,
    });
  }

  // open terms and condition dialog
  openTermsDialog() {
    const dialogRef = this._dialog.open(TermsDialogComponent, {
      panelClass: 'white-dialog',
      width: '92rem',
      minHeight: '76rem',
      autoFocus: false,
    });
  }

  // scroll to the required question
  scrollQuestion() {
    // Refactor Try using viewChild or render2 or elementRef by that order
    let element: any = this._elemRef.nativeElement.querySelectorAll('.modify');
    if (this.questionElement) {
      (this.screenWidth > 768
        ? (this.questionElement.nativeElement as HTMLElement)
        : window
      ).scroll({
        top: (element[0] as HTMLElement)?.offsetTop,
        left: 0,
        behavior: 'smooth',
      });

      if (
        this.myForm.form.status == 'VALID' &&
        Object.keys(this.modifyObject).length === 0
      ) {
        this.checkValueModify();
      }

      if (element.length == 0 && this.myForm.form.status == 'VALID') {
        let privacySection = document.querySelectorAll('.privacy');
        (this.screenWidth > 768
          ? (this.questionElement.nativeElement as HTMLElement)
          : window
        ).scroll({
          top: (privacySection[0] as HTMLElement)?.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  // remove class modify when user add answer
  checkValueModify() {
    let element = this._elemRef.nativeElement.querySelectorAll('.modify');
    element[0]?.classList.remove('modify');
    element[1]?.classList.remove('modify');
  }

  // check question credibility
  checkQuestionCredibility(newItem: any) {
    if (newItem.data) {
      this.sections?.filter((section) => {
        if (section.id == newItem.data[0].section_id) {
          const index = section.questions.findIndex((object: any) => {
            return object.id === newItem.id;
          });
          section.questions.splice(index + 1, 0, ...newItem.data);
        }
      });
    } else {
      this.sections?.filter((section) => {
        section.questions = section.questions?.filter((quest) => {
          return quest.parent_id != newItem.id;
        });
      });
    }
  }

  // Navigate to service plans page
  navigateToServicePlans() {
    if (this.serviceId) {
      this._router.navigate([`/services/${this.serviceId}`]);
    } else {
      this._router.navigate([`/services/${this.draftServiceId}`]);
    }
  }

  ngOnDestroy(): void {
    if (this.routeSub) this.routeSub.unsubscribe();
    if (this.sectionsSub) this.sectionsSub.unsubscribe();
    if (this.updateCaseSub) this.updateCaseSub.unsubscribe();
    if (this.createCaseSub) this.createCaseSub.unsubscribe();
    if (this.activeRouteSub) this.activeRouteSub.unsubscribe();
    if (this.caseSub$) this.caseSub$.unsubscribe();
  }
}
