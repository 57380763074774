<div class="services-list">
  <h2 class="services-list__header">اختر خدمتك</h2>

  <!--  spinner  -->
  <div *ngIf="!mainServices" class="services-list__spinner">
    <mat-spinner></mat-spinner>
  </div>

  <!-- services items wrapper  -->
  <div *ngIf="mainServices" class="services-list__items-wrapper">
    <!-- service item -->
    <div
      *ngFor="let service of mainServices"
      class="services-list__items-wrapper__service-item"
    >
      <figure>
        <img [src]="service.imageUrl" alt="service item" />
        <figcaption>
          {{ service.title }}
        </figcaption>
      </figure>
      <div class="overlay" (click)="navigateToServicePlans(service.id)">
        <h4 class="overlay__header">
          <span>
            {{ service.title }}
          </span>
          <mat-icon class="material-icons-outlined"> navigate_before </mat-icon>
        </h4>
        <p>
          {{ service.description }}
        </p>
      </div>
    </div>

    <div *ngIf="mainServices && mainServices.length == 0" class="empty-list">
      <div class="empty-list__content">
        <img src="../../../../assets/images/empty-page.png" alt="empty page" />
        <h2 class="empty-list__content__title">لا يوجد خدمات بعد</h2>
      </div>
    </div>
  </div>
</div>
