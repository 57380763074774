<!-- start of landing page -->
<div>
  <app-ad-slider></app-ad-slider>
  <app-services-list class="servicesList"></app-services-list>

  <button class="services-btn" (click)="redirectToServicesPage()" type="button" mat-button #servicePricesBtn>
    الخدمات و الأسعار
  </button>
  <!-- link to create inquiry -->
  <div class="inquiry">
  </div>
</div>
<!-- end of landing page -->