// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api_url: 'https://ozwahapidev.kortobaa.net/api/',
  publishable_api_key: 'pk_test_xobN8UwYeQBGFm184uhzGFnqH8LacfrDXcVrXLSz',
  snap_pixel_key: 'e11b787d-b95b-4900-9dfc-48f47aeb2686',
  closeMaintenance: false,
  firebase: {
    apiKey: 'AIzaSyASg3KqUEmDga9R8Ao3nPMj9vU7gPpCHAg',
    authDomain: 'ozwah-67901.firebaseapp.com',
    databaseURL: 'https://ozwah-67901-default-rtdb.firebaseio.com',
    projectId: 'ozwah-67901',
    storageBucket: 'ozwah-67901.appspot.com',
    messagingSenderId: '128841186122',
    appId: '1:128841186122:web:7501df1726c8a75f8d9fb3',
    measurementId: 'G-XJSD1S26K3',
    vapidKey:
      'BKjFoYmhBIXjZHzeIy7nsVfWpW3G4rPmB4iWRXGSHq0vcDbxJZw-W80fcDh6uMkV2nUcekKisiKD82WFrErG7B0',
  },
  whatsappSupport: '+966504461988',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
